
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface restoreData {
  document_type: string;
  document_id: string;
}

@Component
export default class SendVerificationForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;
  options: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];
  protected formData: restoreData = {
    document_type: "1",
    document_id: ""
  };

  private credentials_found = true;
  private user_email = "";
  private confirm = false;

  private confirmEmail(): boolean {
    this.$q.loading.show();
    this.$axios
      .get("personas/recuperar/contrasena/email", {
        params: {
          filter: {
            where: {
              and: [
                { rut: this.formData.document_id },
                { tipo_documento: this.formData.document_type },
                { estado: 1 },
                { tipo: 1 }
              ]
            },
            fields: ["id", "rut", "tipo_documento", "email", "estado"]
          }
        }
      })
      .then(response => {
        this.user_email = response.data;
        this.confirm = true;
      })
      .catch(error => {
        this.setCredentialsNotFound();
      })
      .finally(() => {
        this.$q.loading.hide();
      });
    return true;
  }

  private confirmReset(): boolean {
    this.$q.loading.show();
    this.$axios
      .patch("/personas/sendVerify", {
        tipo_documento: Number(this.formData.document_type),
        rut: this.formData.document_id
      })
      .then(response => {
        this.$router.push({ name: "newrequest" });
        this.$q.notify({
          message:
            "Correo enviado, revise su casilla de correo en los próximos minutos para continuar con la validación de su correo.",
          color: "principal",
          position: "top",
          timeout: 7500
        });
      })
      .catch(error => {
        this.$router.push({ name: "newrequest" });
        this.$q.notify({
          message: "Ocurrió un error al reenviar el correo de validación.",
          color: "principal",
          position: "top",
          timeout: 7500
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
    return true;
  }

  private setCredentialsNotFound() {
    this.credentials_found = false;
    (this.$refs.form as any).validate();
  }

  private credentialsFound() {
    if (this.credentials_found) {
      return true;
    } else {
      return this.$t("validation.user_not_found");
    }
  }
}
